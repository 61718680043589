.searchMain {
  width: 100%;
  display: flex;
  justify-content: center;
}

.searchContainer {
  width: 97%;
}

.searchTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.searchText {
  font-size: 16px;
  font-weight: bold;
  color: rgb(50, 50, 50);
}

.searchInputContainer {
  margin-left: 8px;
}

.searchInput {
  width: 150px;
  height: 22px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 2px;
  padding: 3px;
  box-sizing: border-box;
  text-align: center;
}

.searchBtn {
  width: 37px;
  height: 37px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 2px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #006699;
  border: 0px;
  font-size: 37px;
  background-color: rgb(232, 235, 239);
}
.searchTable {
  width: 100%;
  margin-top: 15px;
  border-collapse: collapse;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  box-sizing: border-box;
}

.table {
  width: 100%;
}

.searchHistoryContainer {
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgb(200, 200, 200);
  background-color: white;
  position: absolute;
  z-index: 3;
  border: 1px solid rgb(200, 200, 200);
  left: 150px;
}

.searchHistoryItem {
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid rgb(200, 200, 200);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 12px;
}

.warningText {
  font-size: 12px;
  color: red;
  margin-left: 50px;
}

.descriptionSearchContainer {
  margin-left: 30px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(50, 50, 50);
}

.checkbox {
  width: 15px;
  height: 15px;
}
