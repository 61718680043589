.loginPageContainer {
  width: 750px;
}

.companyName {
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  color: white;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: rgb(251, 251, 251);
  padding: 20px;
  box-sizing: border-box;
}

.loginForm {
  width: 96%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.loginTextContainer {
  width: 96%;
}

.secondaryText {
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-left: 15px;
}

.inputContainer {
  width: 220px;
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputContainer input {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px;
  padding-left: 8px;
  box-sizing: border-box;
  outline: none;
  background-color: rgb(251, 251, 251);
}

.inputContainer input:focus {
  border: 1px solid blue;
}

.btnContainer {
  margin-top: 20px;
}

.labelForm {
  margin-bottom: 5px;
}

.overlayText {
  position: absolute;
  text-align: center;
  top: -23px;
  left: 30px;
  font-size: 12px;
  color: #6a6a6a;
  background-color: rgb(251, 251, 251);
  width: 110px;
  box-sizing: border-box;
  padding: 1px;
  z-index: 1;
}

.viberLogo {
  width: 60px;
  height: 60px;
  margin-top: 20px;
  color: #9200c3;
  cursor: pointer;
}

.phoneContainer {
  color: rgb(37, 37, 227);
  font-size: 20px;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: none;
}

.phoneLink {
  text-decoration: none;
}

.registreTextCont {
  margin-top: 20px;
}

.registreText {
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-left: 15px;
  text-decoration: none;
}

.loginBtn {
  background-color: rgb(130, 130, 130);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBtn:hover {
  background-color: rgb(110, 110, 110);
}

.eyeIcon {
  position: absolute;
  top: 25px;
  right: -20px;
  color: rgb(110, 110, 110);
  cursor: pointer;
  background-color: #ffffff;
}

@media (max-width: 770px) {
  .loginPageContainer {
    width: 90%;
  }
}
