.resetPasswordPageContainer {
  width: 750px;
}

.companyName {
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  color: white;
}

.resetPasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: rgb(251, 251, 251);
  box-sizing: border-box;
  padding: 20px;
}

.resetPasswordForm {
  width: 96%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.resetPasswordTextContainer {
  width: 96%;
}

.secondaryText {
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-left: 15px;
}

.inputContainer {
  width: 170px;
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputContainer input {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px;
  padding-left: 8px;
  box-sizing: border-box;
  outline: none;
  background-color: rgb(251, 251, 251);
}

.inputContainer input:focus {
  border: 1px solid blue;
}
.inputResetPasswordContainer {
  width: 170px;
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputResetPasswordContainer input {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px;
  padding-left: 8px;
  box-sizing: border-box;
  outline: none;
  background-color: rgb(251, 251, 251);
}

.inputResetPasswordContainer input:focus {
  border: 1px solid blue;
}
.inputVerificationContainer {
  width: 170px;
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
}

.inputVerificationContainer input {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px;
  box-sizing: border-box;
  outline: none;
  background-color: rgb(251, 251, 251);
  width: 30px;
  text-align: center;
}

.inputVerificationContainer input:focus {
  border: 1px solid blue;
}

.btnContainer {
  margin-top: 20px;
}

.labelForm {
  margin-bottom: 5px;
}

.overlayText {
  position: absolute;
  text-align: center;
  top: -23px;
  left: 30px;
  font-size: 12px;
  color: #6a6a6a;
  background-color: rgb(251, 251, 251);
  width: 140px;
  box-sizing: border-box;
  padding: 1px;
  z-index: 1;
}

.overlayTextReturn {
  position: absolute;
  text-align: center;
  top: -23px;
  left: 30px;
  font-size: 12px;
  color: #6a6a6a;
  background-color: rgb(251, 251, 251);
  width: 70px;
  box-sizing: border-box;
  padding: 1px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.resetPasswordTextCont {
  margin-top: 20px;
}

.newCodeText {
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-top: 15px;
}

.resetPasswordText {
  color: rgb(110, 110, 110);
  font-size: 14px;
  margin-left: 15px;
  text-decoration: none;
}

.resetPasswordBtn {
  background-color: rgb(130, 130, 130);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  width: auto;
}

.resetPasswordBtn:hover {
  background-color: rgb(110, 110, 110);
}

.resetPasswordBtnDisabled {
  background-color: rgb(130, 130, 130);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;

  outline: none;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  min-width: 100px;
  width: auto;
}

.correctLogo {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 22px;
  left: 180px;
  color: green;
}

.resendCodeCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  margin-top: 10px;
}

.newCodeBtn {
  background-color: rgb(130, 130, 130);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 12px;
}
.verifyCodeBtn {
  background-color: rgb(130, 130, 130);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 18px;
}

.eyeIcon {
  position: absolute;
  top: 25px;
  right: -20px;
  color: rgb(110, 110, 110);
  cursor: pointer;
  background-color: #ffffff;
}

@media (max-width: 770px) {
  .registrePageContainer {
    width: 90%;
  }
}
