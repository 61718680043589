.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  width: 60px;
  height: 60px;
  margin: 10px;
  border-top: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  border-left: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logoIcon {
  width: 60px;
  height: 60px;
}

.loaderText {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
}
