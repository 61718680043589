.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.modalContainer {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.arrowButton {
  background-color: transparent;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  color: white;
  padding: 0 20px;
}

.arrowButton:hover {
  color: #ccc;
}

.closeIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 3rem;
  color: rgb(36, 36, 36);
  cursor: pointer;
}

.dotsContainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.activeDot {
  background-color: #ffffff;
}
