.modalContainer {
  box-sizing: border-box;
  width: 900px;
  min-height: 220px;
  background-color: rgb(218, 224, 232);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 2px 9px 2px;
  border-radius: 10px;
}

.modalHeader {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(185, 206, 233);
  border-radius: 8px;
  padding: 0px 10px;
  font-weight: bold;
  font-size: 14px;
}

.closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: rgb(117, 168, 231);
  height: 20px;
  width: 20px;
}

.closeIcon:hover {
  color: rgb(84, 148, 226);
}

.modalHR {
  width: 95%;
  margin-top: 27px;
}

.infoText {
  font-size: 12px;
  color: rgb(50, 50, 50);
  width: 30%;
}

.infoBtn {
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(163, 184, 210);
  border-radius: 8px;
  width: 42px;
  height: 32px;
  font-size: 16px;
  color: rgb(70, 127, 197);
  margin-right: 15px;
}

.infoBtn:hover {
  background: linear-gradient(to top, #dfe9f3, #cbddeb);
  color: rgb(84, 148, 226);
  cursor: pointer;
}

.infoBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.arrowBtn {
  border: none;
  color: #006699;
  background-color: transparent;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
  cursor: pointer;
}
.arrowBtn[disabled] {
  border: none;
  color: rgb(163, 184, 210);
  background-color: transparent;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px;
  cursor: not-allowed;
}

.pageNumberInput {
  width: 50px;
  height: 16px;
  border: 1px solid rgb(163, 184, 210);
  border-radius: 5px;
  text-align: center;
}

.historyPaymentTable {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
  width: 95%;
  margin-top: 10px;
}

.historyPaymentTable th {
  border: 1px solid black;
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.historyPaymentTable td {
  box-sizing: border-box;
  border: 1px solid black;
  text-align: center;
  font-size: 14px;
  padding: 8px 10px 8px 3px;
  position: relative;
  height: 40px;
}

.noPaymentHistory {
  font-size: 24px;
  color: rgb(50, 50, 50);
  margin-top: 10px;
}
