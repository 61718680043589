.pageContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstBlockContainer {
  box-sizing: border-box;
  width: 70%;
  height: 60vh;
  background-color: rgb(251, 251, 251);
  border-radius: 0px 0px 20px 20px;
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 5px 7px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.navContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 5%;
  width: 80%;
  padding: 0 10px;
  justify-content: space-between;
}

.logoContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 120px;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
  font-size: 24px;
}

.socIcon {
  color: rgb(87, 87, 87);
}

.socIcon:hover {
  color: black;
  cursor: pointer;
}

.loginContainer {
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobileLoginContainer {
  width: 180px;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.loginBtn {
  background-color: rgb(130, 130, 130);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.loginBtn:hover {
  background-color: rgb(110, 110, 110);
}

.signUpBtn {
  border: 0;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.firstBlockContentContainer {
  height: 95%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.contentBlock {
  height: 100%;
  width: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgContentBlock {
  height: 100%;
  width: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.engineBg {
  position: absolute;
  bottom: -150px;
  right: 20px;
  transform: rotate(20deg);
}

.title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subTitle {
  color: rgb(110, 110, 110);
  font-weight: bold;
}

.carouselContainer {
  width: 70%;
  overflow: hidden;
}

.secondBlockContainer {
  box-sizing: border-box;
  width: 70%;
  height: 85vh;
  background-color: rgb(251, 251, 251);
  border-radius: 20px 20px 0px 0px;
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 5px 7px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.titleContacts {
  font-size: 42px;
  font-weight: bold;
  margin: 50px;
}

.cityPickerContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  border: 2px solid rgb(143, 143, 143);
  height: 70%;
  border-radius: 8px;
  box-shadow: 0 0 5px 7px rgba(189, 189, 189, 0.1);
}

.cityContainer {
  box-sizing: border-box;
  width: 25%;
  height: 100%;
  background-color: rgb(233, 233, 233);
  padding: 0px 10px;
}

.data {
  width: 25%;
  height: 100%;
}

.photo {
  width: 44%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.city {
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  height: 60px;
  border: 1px solid rgb(143, 143, 143);
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  cursor: pointer;
}

.city:hover {
  background-color: #f0f0f0;
}

.activeCity {
  border-color: rgb(0, 123, 255);
  background-color: #e6f7ff;
}

.cityTitle {
  font-weight: bold;
}

.cityStreetTitle {
  font-size: 14px;
}

.photoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mainPhoto {
  width: 420px;
  height: 370px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.controlsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.thumbnailContainer {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.thumbnail {
  width: 70px;
  height: 70px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.thumbnail:hover {
  border-color: #007bff;
}

.activeThumbnail {
  border-color: #007bff;
}

.arrowButton {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(100, 100, 100);
  border: none;
  cursor: pointer;
  font-size: 48px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.arrowButton:nth-child(2) {
  left: 10px;
}

.arrowButton:nth-child(3) {
  right: 10px;
}

.thumbnailContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
  width: 100%;
}

.thumbnail {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.thumbnail:hover {
  border-color: #007bff;
}

.activeThumbnail {
  border-color: #007bff;
}

.shopInfoContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 15px;
}

.shopInfo {
  height: 33%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.iconContainer {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: #419dff;
  margin-right: 15px;
}

.infoContainer {
  width: 66%;
  height: 37%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.titleInfo {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 7px;
}

.secondaryTitleInfo {
  margin-bottom: 5px;
}

@media (max-width: 1600px) {
  .imgContentBlock {
    display: none;
  }

  .contentBlock {
    width: 100%;
  }

  .firstBlockContainer {
    width: 80%;
  }

  .cityPickerContainer {
    flex-direction: column;
    height: auto;
    padding: 10px;
    align-items: center;
  }

  .cityContainer {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    text-align: center;
    padding: 15px;
  }

  .data {
    width: 100%;
    margin-bottom: 10px;
  }

  .photoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
  }

  .mainPhoto {
    width: 400px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  .controlsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    width: 400px;
  }

  .thumbnailContainer {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 400px;
    margin-bottom: 30px;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: border 0.3s ease;
  }

  .thumbnail:hover {
    border-color: #007bff;
  }

  .activeThumbnail {
    border-color: #007bff;
  }

  .secondBlockContainer {
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .shopInfoContainer {
    flex-direction: row;
  }
}

@media (max-width: 1024px) {
  .firstBlockContainer {
    width: 100%;
  }

  .secondBlockContainer {
    width: 100%;
  }

  .mainPhoto {
    width: 300px;
    height: 250px;
  }

  .thumbnail {
    width: 50px;
    height: 50px;
  }

  .controlsContainer {
    width: 300px;
  }

  .thumbnailContainer {
    width: 300px;
  }

  .arrowButton {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .firstBlockContainer {
    width: 100%;
  }

  .secondBlockContainer {
    width: 100%;
    padding: 10px;
  }

  .loginContainer {
    display: none;
  }

  .title {
    font-size: 30px;
  }

  .subTitle {
    width: 80%;
  }

  .mobileLoginContainer {
    display: flex;
  }

  .navContainer {
    width: 95%;
  }

  .cityPickerContainer {
    flex-direction: column;
    gap: 15px;
  }

  .mainPhoto {
    width: 250px;
    height: 200px;
  }

  .controlsContainer {
    width: 250px;
  }

  .thumbnailContainer {
    width: 250px;
  }

  .shopInfoContainer {
    flex-direction: column;
  }

  .shopInfo {
    margin-bottom: 25px;
  }

  .arrowButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .arrowButton:nth-child(1) {
    left: -40px;
  }

  .arrowButton:nth-child(3) {
    right: -40px;
  }

  .thumbnail {
    width: 40px;
    height: 40px;
  }
}
