.tableHeader {
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  border: 1px solid black;
}

.searchTable {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
  margin-top: 15px;
}

.tableRowBrand {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: lightyellow;
  font-size: 12px;
  padding: 3px;
}

.tableRowBrandAsterix {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(235, 235, 212);
  font-size: 12px;
  padding: 3px;
}
