.paymentPlanTable {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
}

.paymentPlanTable th {
  border: 1px solid black;
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 200px;
}

.paymentPlanTable td {
  box-sizing: border-box;
  border: 1px solid black;
  text-align: center;
  font-size: 12px;
  padding: 8px 10px 8px 3px;
  position: relative;
}

.paymentPlanPageContainer {
  width: 100%;
}

.paymentPlanContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.noPaymentPlanText {
  font-size: 20px;
  font-weight: 700;
  color: #383838;
}

.hideZeroSumDaysContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.hideZeroSumDaysContainer span {
  font-size: 14px;
  margin-left: 5px;
}
