.imgContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 200px;
  height: 150px;
}

.carouselContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: rgb(130, 130, 130);
}

.carouselContainer::before,
.carouselContainer::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150px;
  z-index: 1;
}

.carouselContainer::before {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 1),
    rgba(130, 130, 130, 0)
  );
}

.carouselContainer::after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(130, 130, 130, 1),
    rgba(130, 130, 130, 0)
  );
}
