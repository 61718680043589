.modalContainer {
  box-sizing: border-box;
  width: 300px;
  min-height: 110px;
  background-color: rgb(218, 224, 232);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 2px 9px 2px;
  border-radius: 10px;
}

.modalHeader {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(185, 206, 233);
  border-radius: 8px;
  padding: 0px 10px;
  font-weight: bold;
  font-size: 14px;
}

.closeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: rgb(117, 168, 231);
  height: 20px;
  width: 20px;
}

.closeIcon:hover {
  color: rgb(84, 148, 226);
}

.modalHR {
  width: 95%;
}

.infoBtn {
  background: linear-gradient(to top, #cbddeb, #dfe9f3);
  border: 1px solid rgb(163, 184, 210);
  border-radius: 8px;
  width: 42px;
  height: 32px;
  font-size: 16px;
  color: rgb(70, 127, 197);
  margin-right: 15px;
}

.infoBtn:hover {
  background: linear-gradient(to top, #dfe9f3, #cbddeb);
  color: rgb(84, 148, 226);
  cursor: pointer;
}

.infoBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.noSparesContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 20px;
}

.noSparesIcon {
  font-size: 60px;
  color: rgb(197, 0, 0);
}

.noSparesTextContainer {
  width: 50%;
  margin-left: 25px;
  font-size: 14px;
}
