.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 500px;
  background-color: #eceef1;
  color: #1b1a1a;
  border-radius: 10px;
  margin-top: 100px;
}

.notFoundHeader {
  font-size: 60px;
  font-weight: bold;
  color: #2c2a2a;
}

.returnLinkContainer {
  margin-top: 20px;
}

.returnLink {
  font-size: 20px;
  font-weight: bold;
  color: rgb(91, 91, 238);
  cursor: pointer;
  text-decoration: none;
}

.notFoundTextContainer {
  margin-top: 20px;
}
