.viberLogo {
  width: 60px;
  height: 60px;
  margin-top: 20px;
  color: #9200c3;
  cursor: pointer;
}

.phoneContainer {
  color: rgb(37, 37, 227);
  font-size: 20px;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: none;
}

.phoneLink {
  text-decoration: none;
}
