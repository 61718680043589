.infoPage {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.generalBtn {
  margin-right: 10px;
  margin-left: 10px;
  height: 30px;
  padding: 5px;
  width: 140px;
  border: none;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: #505050;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(39, 39, 39, 0.2);
  transition: 0.3s;
}

.checkbox {
  margin-left: 30px;
  height: 15px;
  width: 15px;
}

.hideIncomePricesText {
  font-size: 14px;
  margin-left: 5px;
}

.pendingOrdersContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
  flex-direction: column;
}
.pendingOrdersContainer h3 {
  font-size: 16px;
  font-weight: 700;
  color: #383838;
}

.pendingOrderTable {
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
}

.pendingOrderTable th {
  border: 1px solid black;
  box-sizing: border-box;
  height: 25px;
  background-color: #006699;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.pendingOrderTable td {
  box-sizing: border-box;
  border: 1px solid black;
  text-align: center;
  font-size: 12px;
  padding: 8px;
  position: relative;
}

.orderIsConfirmed {
  background-color: rgb(185, 240, 185);
}

.orderIsRejected {
  background-color: rgb(238, 175, 175);
}

.orderIsPending {
  background-color: rgb(246, 246, 212);
}

.deleteTd {
  text-align: center;
}

.deleteBtn {
  color: rgb(196, 18, 18);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.deleteBtn:hover {
  color: rgb(255, 0, 0);
}

.noOrdersText {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  margin-left: 40px;
}

.orderedSumContainer {
  width: 100%;
  margin-bottom: 10px;
}
